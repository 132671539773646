@font-face {
  font-family: 'DigitalStrip';
  font-style: normal;
  font-weight: normal;
  src: local('DigitalStrip'), url('/fonts/digital_strip/digistrip.woff') format('woff');
}

@font-face {
  font-family: 'DigitalStrip Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DigitalStrip Italic'), url('/fonts/digital_strip/digistrip_i.woff') format('woff');
}

@font-face {
  font-family: 'DigitalStrip Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DigitalStrip Bold'), url('/fonts/digital_strip/digistrip_b.woff') format('woff');
}

body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
