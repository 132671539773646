@mixin responsive-max($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin responsive-min($breakpoint) {
  @media (min-width: #{$breakpoint + 1px}) {
    @content;
  }
}

$gray-1: #fff;
$gray-2: #fafafa;
$gray-7: #8c8c8c;
$gray-10: #000;

$footer-height: 800px;
$logo-pc-width: 107px;
$logo-sp-width: 67px;

// FONT WEIGHT
$fw-medium: 500;
$fw-bold: 800;

$inner-wrapper-padding-x-sp: 20px;

%inner-wrapper {
  width: 100%;
  max-width: 948px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: var(--main-content-width)) {
    padding-left: $inner-wrapper-padding-x-sp;
    padding-right: $inner-wrapper-padding-x-sp;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 20px 45px;
  position: relative;
  overflow: hidden;
  background-color: #000;
  color: $gray-1;
  font-family: Montserrat, sans-serif;

  a {
    text-decoration: none;
  }

  .empty {
    flex: 1;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 0 40px;
  }
}

.contentArea {
  display: flex;
  position: relative;
}

// LOGO AREA
.logoArea {
  width: 200px;
}

.logoInkr {
  width: $logo-pc-width;
  margin-bottom: 20px;
}

.social {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}

.socialIcon {
  width: 20px;
}

.socialText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  padding-left: 8px;
  padding-top: 5px;
  color: #fff;
}

// LINKS AREA
.linksArea {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-wrap: wrap;
  text-align: right;
}

.linkList {
  flex: 1;
  padding-left: 20px;
  margin-bottom: 0;
}

.linkItem {
  list-style: none;

  .link {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-transform: capitalize;
    color: $gray-1;
  }

  .linkLabel {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: $gray-1;
    opacity: 0.4;
  }

  margin-bottom: 12px;
}

.inner {
  @extend %inner-wrapper;
}

.copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray-1;
  opacity: 0.4;
  margin-top: 20px;

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.copyrightMob {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: $gray-1;
  opacity: 0.5;
}

@media screen and (max-width: 576px) {
  .linksArea {
    display: none;
  }

  .linkList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .linkItem {
      padding-right: 15px;
      margin-bottom: 10px;
    }
  }
}

// FOOTER

@include responsive-max(940px) {
  .contentArea {
    display: block;
  }

  .logoArea {
    margin-bottom: 40px;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .linksArea {
    text-align: left;
  }

  .linkList {
    padding-left: 0;
    padding-right: 20px;
    margin-bottom: 20px;
  }
}

@include responsive-max(768px) {
  .linkList {
    flex: 1;
    min-width: 30%;
  }
}

@include responsive-max(640px) {
  .logoInkr {
    display: inline-block;
    width: $logo-sp-width;
    margin-bottom: 16px;

    svg {
      width: 100%;
    }
  }
}

.footerMob {
  display: none;

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.logoFooter {
  max-width: 107px;
}
